import {
    FrameShape,
    ProductAdditionType,
    ProductType
} from "vtom-api-typescript-client";

export class ProductTypeUtil {
    static toLabelCode(value: ProductType): string {
        return 'product.type.' + value.toLowerCase();
    }
}

export class ProductAdditionTypeUtil {
    static toLabelCode(value: ProductAdditionType): string {
        return 'product.addition.type.' + value.toLowerCase();
    }
}

export class FrameShapeUtil {
    static toLabelCode(value: FrameShape): string {
        return 'frame.shape.' + value.toLowerCase();
    }
}
