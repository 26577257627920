import {ScenarioFileType, ScenarioType} from "vtom-api-typescript-client";

export class ScenarioFileTypeUtils {
    static getAvailable(scenarioType: ScenarioType): ScenarioFileType[] {
        if (scenarioType == ScenarioType.TOP) {
            return [ScenarioFileType.FACE];
        }
        if (scenarioType == ScenarioType.PRINT) {
            return [ScenarioFileType.FACE, ScenarioFileType.THREEQUARTER];
        }
        if (scenarioType == ScenarioType.FACEQUARTERQUARTER) {
            return [ScenarioFileType.FACE, ScenarioFileType.LEFT, ScenarioFileType.RIGHT];
        }
        if (scenarioType == ScenarioType.FACEPROFILETHREEQUARTER
            || scenarioType == ScenarioType.FACEPROFILETHREEQUARTERWITHCLIP) {
            return [ScenarioFileType.FACE, ScenarioFileType.PROFILE, ScenarioFileType.THREEQUARTER];
        }
        if (scenarioType == ScenarioType.COLORMODEPRIMARY
            || scenarioType == ScenarioType.COLORMODESECONDARY) {
            return [ScenarioFileType.FACE, ScenarioFileType.LEFT, ScenarioFileType.RIGHT, ScenarioFileType.FOLDEDTEMPLE];
        }

        return [];
    }
}
