import {IComponentOptions, IController, IPromise, IQService} from "angular";
import * as template from "./try-on-video.component.html";
import {mainModule} from "../app";
import {TryOnApi, TryOnApiService} from "./try-on-api-service";
import {Product, ProductsApi, Scenario, ScenarioStatus} from "vtom-api-typescript-client";
import {ImageSource} from "@cosium/try-on-api-core";
import {ScenarioTypeUtils} from "../product/scenario/scenario-type-utils";
import {IWebcamDevice, Webcams} from "@cosium/try-on-api-webcams";

/**
 * VTO video component.
 * The video can be either a video file (url parameter) or a webcam feed (webcam parameter).
 */
class Controller implements IController {
    // Input
    scenario: Scenario;
    product: Product;
    url: string;
    webcam = false;

    initialised = false;
    error = false;
    ipdMm = 65;

    private tryOnApi: TryOnApi;
    private webcamDevice: IWebcamDevice;

    constructor(private readonly TryOnApiService: TryOnApiService,
                private readonly ProductsApi: ProductsApi,
                private readonly $q: IQService) {
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.isReady()) {
            return;
        }
        this.assertCompatibleScenario();
        this.displayVto();
    }

    private displayVto(): void {
        this.initialised = false;

        let tryOnApiPromise: IPromise<TryOnApi>;
        let newTryonApi = false;
        if (this.tryOnApi) {
            tryOnApiPromise = this.$q.when(this.tryOnApi);
        } else {
            tryOnApiPromise = this.TryOnApiService.acquire();
            newTryonApi = true;
        }

        tryOnApiPromise
            .then(tryOnApi => {
                this.tryOnApi = tryOnApi;
                return this.getImageSource();
            }).then(imageSource => {
                const renderer = this.tryOnApi.renderer;

                if (newTryonApi) {
                    document.getElementById('try-on-video-renderer-output').appendChild(renderer.getCanvas());
                }
                renderer.setFrameAutoResizing(true);

                return this.$q.all([
                    this.tryOnApi.tracker.setSource(imageSource),
                    renderer.setSource(imageSource)
                ]);
            })
            .then(() => this.tryOnApi.renderer.setTracker(this.tryOnApi.tracker))
            .then(() => {
                this.tryOnApi.tracker.start();
                this.tryOnApi.renderer.start();
                return this.setGlasses();
            })
            .then(() => {
                this.initialised = true;
                this.onIpdChange();
            })
            .catch(error => {
                console.error('Failed to start Try On', error);
                this.error = true;
            });
    }

    private assertCompatibleScenario(): void {
        if (!ScenarioTypeUtils.getVtoTypes().includes(this.scenario.type)
            && !ScenarioTypeUtils.getAdditionTypes().includes(this.scenario.type)) {
            throw 'Scenario must be compatible for VTO.';
        }
    }

    private isReady(): boolean {
        return this.scenario
            && this.product
            && (this.url != null || this.webcam);
    }

    private setGlasses(): Promise<void> {
        const promises = [];
        // Clear previous additions if any
        this.tryOnApi.renderer.getAdditions().forEach(addition => {
            promises.push(this.tryOnApi.renderer.removeAddition(addition));
        });
        const productionScenario = Object.assign({}, this.scenario);
        // Trick the vto lib to accept non-production scenarios.
        productionScenario.status = ScenarioStatus.Production;
        // Regular product
        if (!ScenarioTypeUtils.getAdditionTypes().includes(this.scenario.type)) {
            promises.push(this.tryOnApi.renderer.setGlasses([this.product, productionScenario]));
            return this.$q.all(promises)
                .then(() => {});
        }

        // Addition products
        let additionBaseProduct: Product;
        return this.fetchAdditionBaseProduct()
            .then(product => {
                additionBaseProduct = product;
                promises.push(this.tryOnApi.renderer.setGlasses(additionBaseProduct));
                promises.push(this.tryOnApi.renderer.addAddition(this.scenario));
                return this.$q.all(promises);
            }).then(() => {});

    }

    private getImageSource(): IPromise<ImageSource> {
        if (!this.webcam) {
            return this.$q.when(new ImageSource(document.getElementById('try-on-video-source')));
        }

        let webcamDevidePromise;
        if (this.webcamDevice) {
            this.$q.when(this.webcamDevice);
        } else {
            webcamDevidePromise = Webcams.initAndStart();
        }
        return webcamDevidePromise.then(webcamDevice => {
            this.webcamDevice = webcamDevice;
            const webcamVideoElement = document.getElementById('try-on-webcam-source');
            this.webcamDevice.attach(webcamVideoElement);
            return new ImageSource(webcamVideoElement);
        });

    }

    private fetchAdditionBaseProduct(): Promise<Product> {
        return this.ProductsApi
            .listProducts({compatibleAdditionIds: [this.scenario.productId]})
            .then(page => {
                const content = page.content;
                if (content.length > 0) {
                    return content[0];
                }
                throw 'Addition without base base product.'
            });
    }

    onIpdChange() {
        if (!this.initialised) {
            return;
        }
        this.tryOnApi.tracker.setIPD(this.ipdMm / 10);
    }

    $onDestroy() {
        if (this.tryOnApi) {
            this.tryOnApi.release();
        }
        if (this.webcamDevice) {
            this.webcamDevice.dispose();
        }
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        scenario: '<',
        product: '<',
        url: '@',
        webcam: '@',
    }
};

mainModule.component('tryOnVideo', component);
