import * as angular from 'angular';
import {
    IComponentOptions,
    IController,
    IHttpParamSerializer,
    ILocationService,
    IQService, IScope,
    IWindowService
} from 'angular';
import * as template from './job-validation.component.html';
import {mainModule} from '../app';
import {
    Job,
    JobsApi,
    ListProductsRequest,
    ListScenariosRequest,
    Product,
    ProductsApi,
    Scenario,
    ScenariosApi,
    ScenarioStatus
} from 'vtom-api-typescript-client';
import {ApiPageableIterator} from '../api/api-pageable-iterator';
import {ScenarioTypeUtils} from "../product/scenario/scenario-type-utils";
import * as scenarioRejectionDialogTemplate from "./scenario-rejection-dialog.html";

class Controller implements IController {
    // Input
    jobId: number;

    scenarios: Scenario[];
    products: Product[];
    validations: ScenarioValidation[];
    job: Job;
    displayImageExport = false;

    selectedScenario: Scenario;
    selectedProductScenarios: Scenario[];

    toScenarioTypeLabelCode = ScenarioTypeUtils.toLabelCode;
    getVtoScenarioTypes = ScenarioTypeUtils.getVtoTypes;

    constructor(
        private readonly $q: IQService,
        private readonly $location: ILocationService,
        private readonly $translate: angular.translate.ITranslateService,
        private readonly $window: IWindowService,
        private readonly $scope: IScope,
        private readonly $httpParamSerializer: IHttpParamSerializer,
        private readonly NavbarService,
        private readonly DialogService,
        private readonly ExportScenarioResource,
        private readonly ScenarioValidationResource,
        private readonly JobsApi: JobsApi,
        private readonly ScenariosApi: ScenariosApi,
        private readonly ProductsApi: ProductsApi,
        private readonly ApiPageableIterator: ApiPageableIterator) {
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.jobId || onChangesObj.jobId.currentValue == onChangesObj.jobId.previousValue) {
            return;
        }


        this.JobsApi
            .retrieveJob({jobId: this.jobId})
            .then(job => {
                this.job = job;
                this.ExportScenarioResource.findByCustomer({customer: {id: this.job.customerId}})
                    .$promise.then(exportScenario => this.displayImageExport = exportScenario?.id != null);
                this.NavbarService.setAppTitle(
                    this.$scope,
                    this.$translate('product.waiting.validation.forjob',
                        {
                            jobName: this.job.name,
                            totalProducts: this.job.requestedProductNumber,
                            shootedProducts: this.job.requestedProductNumber - this.job.remainingProductBeforeClose
                        })
                );
            });

        this.loadScenarios();
    }

    getProduct(scenario: Scenario): Product {
        return this.products.find(product => product.id == scenario.productId);
    }

    getValidation(scenario: Scenario): ScenarioValidation {
        return this.validations.find(validation => validation.scenario.id == scenario.id)
            || {scenario: scenario};
    }

    getScenariosToValidate(): Scenario[] {
        return this.scenarios.filter(scenario => scenario.status == ScenarioStatus.Setup);
    }

    private addValidation(validation: ScenarioValidation): void {
        if (this.validations.includes(validation)) {
            return;
        }
        this.validations.push(validation);
    }

    private loadScenarios() {
        this.validations = [];
        const scenariosFilter: ListScenariosRequest = {
            jobIds: [this.jobId],
            status: [ScenarioStatus.Setup, ScenarioStatus.SetupRejected, ScenarioStatus.Rejected, ScenarioStatus.Production],
            pageSize: 500
        };
        this.ApiPageableIterator
            .fetchAll(this.ScenariosApi.listScenarios, scenariosFilter)
            .then(scenarios => {
                this.scenarios = scenarios;
                const scenariosToValidate = this.getScenariosToValidate();
                if (scenariosToValidate.length == 0) {
                    this.back();
                    return;
                }
                this.select(scenariosToValidate[0]);
                const productFilter: ListProductsRequest = {
                    ids: scenarios.map(scenario => scenario.productId),
                    pageSize: 500
                };
                return this.ApiPageableIterator.fetchAll(this.ProductsApi.listProducts, productFilter);
            }).then(products => this.products = products);
    }

    reject(scenario: Scenario): void {
        const validation = this.getValidation(scenario);
        this.DialogService.openModal({
            template: scenarioRejectionDialogTemplate.default,
            controller: 'ScenarioRejectionDialogCtrl',
            resolve: {
                scenarioValidation: function () {
                    return validation;
                }
            }
        }).result.then(() => {
            validation.action = 'REJECT';
            this.addValidation(validation)
        });
    };

    validate(scenario: Scenario): void {
        const validation = this.getValidation(scenario);
        validation.action = 'VALIDATE';
        this.addValidation(validation);
    };

    select(scenario: Scenario): void {
        this.selectedScenario = scenario;
        this.selectedProductScenarios = this.scenarios.filter(s => s.productId == scenario.productId);
    };


    downloadAllImages(): void {
        let params = {
            jobId: this.job.id,
            productStatus: 'Setup',
            customerId: this.job.customerId,
        };
        this.$translate('confirm.download.all.images').then(
            message => this.DialogService.confirm(message).result.then(
                () => this.$window.open('api/product/archive?' + this.$httpParamSerializer(params)))
        );
    };

    save(): void {
        const scenariosToValidate = this.validations.filter(validation => validation.action == 'VALIDATE');
        const scenariosToReject = this.validations.filter(validation => validation.action == 'REJECT');
        this.DialogService.confirm(this.$translate('product.production.validation.save.confirm', {
            toValidateCount: scenariosToValidate.length,
            toRejectCount: scenariosToReject.length
        })).result.then(() => {
            this.ScenarioValidationResource.validateAll({productValidations: this.validations})
                .$promise.then(() => this.loadScenarios());
        });
    }

    back(): void {
        this.$location.path('/validation');
    }
}

type ScenarioValidation = {
    scenario: Scenario;
    action?: string;
    comment?: string;
};

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        jobId: '@',
    },
};

mainModule.component('jobValidation', component);
