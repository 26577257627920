import * as angular from 'angular';
import {UserContextService} from "../user/user-context-service";
import {BoothResource} from "../../../http/target/classes/openapi/code/api/BoothResource";

angular.module('virtual-try-on-manager')
    .controller('BoothCtrl', function ($scope, $routeParams, $location, $window, $q, $translate,
                                       BoothResource: BoothResource, BoothSettingResource, DialogService, UserContextService: UserContextService) {

        $scope.isNew = $routeParams.id === 'new';

        if ($scope.isNew) {
            $scope.booth = BoothResource._default();
            $scope.boothSettings = BoothSettingResource.query();
            $q.all([$scope.booth.$promise, $scope.boothSettings.$promise])
                .then(function () {
                    if ($scope.boothSettings.length > 0) {
                        $scope.booth.setting = $scope.boothSettings[0];
                    }
                });
        } else {
            $scope.booth = BoothResource.get(parseInt($routeParams.id));
            $scope.booth
                .$promise
                .then(function (booth) {
                    $scope.boothSettings = BoothSettingResource.findAll({query: {customer: booth.setting.customer}});
                });
        }

        UserContextService
            .getContext()
            .then(userContext => $scope.canDelete =
                userContext.hasRole('ADMIN')
                || userContext.hasRole('BOOTHATOR'));


        $scope.save = function () {
            BoothResource.save($scope.booth)
                .$promise
                .then(function (booth) {
                    if ($scope.isNew) {
                        $location.path('/booth/booth/' + booth.id);
                    }
                });
        };

        $scope.delete = function () {
            DialogService.confirm($translate('general.confirmDeletion')).result.then(function () {
                BoothResource
                    .deleteById({id: $scope.booth.id})
                    .$promise
                    .then(() => $window.history.back())
            });
        };

    });
