import {IComponentOptions, IController} from 'angular';
import * as template from './try-on-gallery.component.html';
import {mainModule} from '../app';
import {Scenario, ScenarioFileType} from 'vtom-api-typescript-client';
import {ScenarioFileTypeUtils} from '../product/scenario/scenario-file-type-utils';

class Controller implements IController {
    // Input
    scenarios: Scenario[];

    pictures: Picture[];
    index: number;

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.scenarios) {
            this.pictures = null;
            return;
        }
        this.pictures = [];
        this.scenarios.forEach(scenario => {
            ScenarioFileTypeUtils.getAvailable(scenario.type).forEach(fileType => {
                this.pictures.push({scenario: scenario, type: fileType});
            })
        });
        this.index = 0;
    }

    next() {
        this.index = (this.index + 1) % this.pictures.length;
    }

    previous() {
        this.index = (this.index - 1 + this.pictures.length) % this.pictures.length;
    }

    getCurrentPicture(): Picture|null {
        if (!this.pictures?.length) {
            return null;
        }
        return this.pictures[this.index];
    }
}

type Picture = {scenario: Scenario, type: ScenarioFileType};

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        scenarios: '<'
    }
};

mainModule.component('tryOnGallery', component);
