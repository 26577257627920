import {IComponentOptions, IController} from 'angular';
import * as template from "./try-on-booth.component.html";
import {mainModule} from "../app";
import {Gender, Product, Scenario, ScenarioType} from "vtom-api-typescript-client";
import {ScenarioTypeUtils} from "../product/scenario/scenario-type-utils";


class Controller implements IController {
    // Input
    product: Product;
    scenarios: Scenario[];

    manTabActive = true;
    womanTabActive = false;
    webcamTabActive = false;


    getVtoCompatibleScenario(): Scenario|null {
        return this.getScenarioByTypes(ScenarioTypeUtils.getVtoTypes().concat(ScenarioTypeUtils.getAdditionTypes()));
    }

    getColorModeScenario(): Scenario|null {
        return this.getScenarioByType(ScenarioType.COLORMODEPRIMARY)
            || this.getScenarioByType(ScenarioType.COLORMODESECONDARY);
    }

    getScenarioByType(scenarioType: ScenarioType): Scenario|null {
        return this.getScenarioByTypes([scenarioType]);
    }

    private getScenarioByTypes(scenarioTypes: ScenarioType[]): Scenario|null {
        if (this.scenarios == null) {
            return null;
        }
        return this.scenarios.find(scenario => scenarioTypes.includes(scenario.type));
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.getVtoCompatibleScenario() || !this.product) {
            return;
        }
        if (this.product.gender == Gender.FEMALE) {
            this.womanTabActive = true;
            this.manTabActive = false;
        }

    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        product: '<',
        scenarios: '<',
    }
};

mainModule.component('tryOnBooth', component);
