import {IComponentOptions, IController} from "angular";
import * as template from "./try-on-picture.component.html";
import {mainModule} from "../app";
import {Scenario} from "vtom-api-typescript-client";

class Controller implements IController {
    scenario: Scenario;
    imageType: string;

    imageUrl: string;

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.scenario || !this.imageType) {
            this.imageUrl = null;
            return;
        }
        this.imageUrl = `./api/scenarios/${this.scenario.id}/files/${this.imageType}`;
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        scenario: '<',
        imageType: '<',
    }
};

mainModule.component('tryOnPicture', component);
