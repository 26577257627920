import {IComponentOptions, IController, IPromise, IQService, IScope, IWindowService} from 'angular';
import {UserContextService} from "../user/user-context-service";
import {ProductResource} from "../../../http/target/classes/openapi/code/api/ProductResource";
import * as template from "./product.component.html";
import {mainModule} from "../app";
import {
    ProductAdditionType,
    ProductsApi,
    ProductType,
    Scenario,
    ScenarioStatus,
    UserRole
} from "vtom-api-typescript-client";
import {ProductAdditionTypeUtil, ProductTypeUtil} from "./product-enum-utils";
import {ApiMapperService} from "../api/api-mapper-service";

export type ProductSaveListener = () => IPromise<void>;

class Controller implements IController {
    readonly types = Object.values(ProductType);
    readonly toTypeLabelCode = ProductTypeUtil.toLabelCode;

    readonly additionTypes = Object.values(ProductAdditionType);
    readonly toAdditionTypeLabelCode = ProductAdditionTypeUtil.toLabelCode;

    isAdmin: boolean;
    isShooter: boolean;
    showTryOnBooth: boolean;
    product;
    genders: any[];
    threeSixtyViewTranslationData;
    scenarios: Scenario[];

    private readonly saveListeners: ProductSaveListener[] = [];

    constructor(
        private readonly $scope: IScope,
        private readonly $routeParams,
        private readonly $translate,
        private readonly $q: IQService,
        private readonly $window: IWindowService,
        private readonly UserContextService: UserContextService,
        private readonly NavbarService,
        private readonly ProductResource: ProductResource,
        private readonly DialogService,
        private readonly ProductsApi: ProductsApi,
        private readonly ApiMapperService: ApiMapperService,
    ) {}


    save(): void {
        this.$q.all(this.saveListeners.map(listener => listener())).then(() =>
        this.ProductResource.save(this.product)
            .$promise
            .then((product) => {
                this.product = product;
            })
        );
    };

    loadScenarios(scenarios): void {
        this.scenarios = scenarios
            .filter(scenario => [ScenarioStatus.Setup, ScenarioStatus.Production, ScenarioStatus.Rejected].includes(scenario.status))
            .map(scenario => this.ApiMapperService.toApiScenario(scenario));
        this.showTryOnBooth = this.scenarios.length > 0;
    }

    delete(): void {
        this.DialogService.confirm(this.$translate('general.confirmDeletion')).result
            .then(() => this.ProductsApi.deleteProduct({productId: this.product.id}))
            .then(() => this.$window.history.back());
    };

    $onInit(): void {
        this.NavbarService.setAppTitleFromObjectName('product', this.$scope, 'product.model');

        const id = this.$routeParams.id;
        let productPromise;
        if (id === 'new') {
            productPromise = this.$q.when({});
        } else {
            productPromise = this.ProductResource.get(id).$promise;
        }

        productPromise.then(product => {
            this.product = product;
            return this.UserContextService.getContext();
        }).then(userContext => {
            this.isAdmin = userContext.hasRole(UserRole.ADMIN);
            this.isShooter = this.isAdmin ||  userContext.hasRole(UserRole.SHOOTER);

            if (this.isShooter) {
                this.genders = this.ProductResource.getAllGenders({});
                this.threeSixtyViewTranslationData = {
                    number: this.product.nbThreeSixtyView
                };

            } else {
                this.$translate(this.product.gender.labelCode)
                    .then((translated) => {
                        this.product.genderLabel = translated;
                    });
            }
        });

    }

    addSaveListener(listener: ProductSaveListener): void {
        if (!listener) {
            throw 'Missing listener';
        }
        this.saveListeners.push(listener);
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('product', component);
