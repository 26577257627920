import {ScenarioType} from "vtom-api-typescript-client";

export class ScenarioTypeUtils {
    static getVtoTypes() {
        return [ScenarioType.VTO, ScenarioType.VTO3D];
    }

    static getAdditionTypes() {
        return [ScenarioType.FACECLIP];
    }

    static toLabelCode(value: ScenarioType): string {
        if (!value) {
            return null;
        }
        return 'product.shooting.mode.' + value
            .toLowerCase()
            .replace('three_sixty', '360')
            .replace(/_/g, '.');
    }
}
