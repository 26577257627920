import {IPromise, IQService} from "angular";
import {BoothSettingResource} from "../../../http/target/classes/openapi/code/api/BoothSettingResource";

angular.module('virtual-try-on-manager')
    .controller('BoothSettingBaseCtrl', function ($scope, $routeParams, $location, $translate, $q: IQService,
                                                  BoothSettingResource: BoothSettingResource, CustomerResource, UserContextService, NavbarService, DialogService, BoothResource, BoothSettingProductResource) {

        $scope.isNew = $routeParams.id === 'new';
        const isAdminFrame = $routeParams.context === 'admin';

        let init = async () => {
            const userContext = await UserContextService.getContext();
            $scope.isAdmin = userContext.hasRole('ADMIN');
            const hasRight = $scope.isAdmin || userContext.hasRole('BOOTHSETTINGATOR');
            $scope.canUpdate = hasRight;
            $scope.canDelete = hasRight && isAdminFrame;
            if ($scope.isAdmin) {
                $scope.customers = CustomerResource.findAll({query: {}});
            }
        };
        init();

        if ($scope.isNew) {
            $scope.boothSetting = BoothSettingResource._default();
        } else {
            $scope.boothSetting = BoothSettingResource.get(parseInt($routeParams.id));
        }

        NavbarService.setAppTitleFromObjectName('setting.booths', $scope, 'boothSetting.name');

        $scope.save = function () {
            BoothSettingResource.save($scope.boothSetting)
                .$promise
                .then(function (boothSetting) {
                    if ($scope.isNew) {
                        $location.path('/admin/booth-setting/' + boothSetting.id);
                    } else {
                        $scope.boothSetting = boothSetting;
                    }
                });
        };

        const countBooths = function(): IPromise<number> {
            const query = {
                boothSetting: {id: $routeParams.id}
            }

            return BoothResource.findPage({
                query,
                'pageable': {
                    pageSize: 1
                }
            }).$promise.then(page => page.totalElements);
        }

        const countPrices = function (): IPromise<number> {
            const query = {
                boothSettingIds: [$routeParams.id],
                productIds: []
            }
            return BoothSettingProductResource.findPage({
                'filter': query,
                'pageable': {
                    pageSize: 1
                }
            }).$promise.then(page => page.totalElements);
        }

        $scope.delete = function (): void {
            let boothCount: number, priceCount: number;
            const promises = [];
            promises.push(countBooths().then(count => boothCount = count));
            promises.push(countPrices().then(count => priceCount = count));

            $q.all(promises).then(() => {
                let warningMsg = $translate('general.confirmDeletion')
                if (boothCount > 0 || priceCount > 0) {
                    warningMsg = $translate('setting.booths.delete.warning.booth.exists', {
                        boothsCount: boothCount,
                        pricesCount: priceCount
                    })
                }
                DialogService.confirm(warningMsg).result.then(function () {
                    BoothSettingResource.deleteById({id: $scope.boothSetting.id})
                        .$promise.then(function () {
                        $location.path('/admin/booth-setting/');
                    });
                });
            });
        };
    });
