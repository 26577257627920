import {mainModule} from '../app';
import {IPromise, IQService} from 'angular';

/**
 * Facilitate iterating over paged APIs.
 */
export class ApiPageableIterator {
    static NAME = 'ApiPageableIterator';
    constructor(private readonly $q: IQService) {}

    public fetchAll<Entity>(pageableCall: (request: PageableRequest) => IPromise<Page<Entity>>, request: PageableRequest): IPromise<Entity[]> {
        return this.fetchPagesRecursively(pageableCall, request, 0, 1, []);
    }

    private fetchPagesRecursively<Entity>(pageableCall: (request: PageableRequest) => IPromise<Page<Entity>>,
                                 request: PageableRequest,
                                 pageNumber: number,
                                 pageTotal: number,
                                 results: Entity[]): IPromise<Entity[]> {
        if (pageNumber >= pageTotal) {
            return this.$q.resolve(results);
        }
        const requestAtPage = Object.assign({}, request);
        requestAtPage.pageNumber = pageNumber;
        return pageableCall(requestAtPage).then(page => {
           return this.fetchPagesRecursively(pageableCall, request, pageNumber + 1, page.totalPages, results.concat(page.content));
        });
    }

}

export type PageableRequest = {
    pageNumber?: number;
}

export type Page<Entity> = {
    totalPages?: number;
    content?: Entity[];
}

mainModule.service(ApiPageableIterator.NAME, ApiPageableIterator);

