/**
 * http
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as angular from 'angular';
import {Container} from '../container';
import {ApiResource} from '../resource';

/* tslint:disable:no-unused-variable member-ordering */

export class ProductResource {
    private readonly basePath = 'api';

    static $inject: string[] = ['$http', 'transformResponseAsFile', '$httpParamSerializer'];

    constructor(private $http: ng.IHttpService, private transformResponseAsFile:any, private $httpParamSerializer?: (d: any) => any) {

    }

    /**
     * 
     */
    public _default (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/default';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: '_default'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param id 
     * @param permission 
     */
    public checkPermission (id: number, permission: string, extraHttpRequestParams?: any ) : ApiResource<Container<boolean>> & Container<boolean> {
        const localVarPath = this.basePath + '/std/productService/permission/{permission}/{id}'
            .replace('{' + 'id' + '}', String(id))
            .replace('{' + 'permission' + '}', String(permission));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling checkPermission.');
        }
        // verify required parameter 'permission' is not null or undefined
        if (permission === null || permission === undefined) {
            throw new Error('Required parameter permission was null or undefined when calling checkPermission.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'checkPermission'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        apiResult.$promise = apiResult.$promise.then(data => {return {data: data};});
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public count (payload: any, extraHttpRequestParams?: any ) : ApiResource<Container<number>> & Container<number> {
        const localVarPath = this.basePath + '/std/productService/count';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling count.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'count'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        apiResult.$promise = apiResult.$promise.then(data => {return {data: data};});
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public findAll (payload: any, extraHttpRequestParams?: any ) : ApiResource<Array<any>> & Array<any> {
        const localVarPath = this.basePath + '/std/productService/findAll';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling findAll.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'findAll'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = [];
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public findOne (payload: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/findOne';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling findOne.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'findOne'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public findPage (payload: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/findPage';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling findPage.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'findPage'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public findPageByBoothSetting (payload: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/findPageByBoothSetting';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling findPageByBoothSetting.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'findPageByBoothSetting'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param id 
     */
    public get (id: number, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/{id}'
            .replace('{' + 'id' + '}', String(id));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling get.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'get'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public getAllGenders (payload: any, extraHttpRequestParams?: any ) : ApiResource<Array<any>> & Array<any> {
        const localVarPath = this.basePath + '/std/productService/getAllGenders';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling getAllGenders.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getAllGenders'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = [];
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public getAllProductImportMode (payload: any, extraHttpRequestParams?: any ) : ApiResource<Array<any>> & Array<any> {
        const localVarPath = this.basePath + '/std/productService/getAllProductImportMode';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling getAllProductImportMode.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getAllProductImportMode'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = [];
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public getAllShootingModes (payload: any, extraHttpRequestParams?: any ) : ApiResource<Array<any>> & Array<any> {
        const localVarPath = this.basePath + '/std/productService/getAllShootingModes';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling getAllShootingModes.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getAllShootingModes'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = [];
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public getAllStatuses (payload: any, extraHttpRequestParams?: any ) : ApiResource<Array<any>> & Array<any> {
        const localVarPath = this.basePath + '/std/productService/getAllStatuses';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling getAllStatuses.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getAllStatuses'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = [];
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public getProductColors (payload: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/getProductColors';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling getProductColors.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductColors'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param entityAsMap 
     */
    public save (entityAsMap: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'entityAsMap' is not null or undefined
        if (entityAsMap === null || entityAsMap === undefined) {
            throw new Error('Required parameter entityAsMap was null or undefined when calling save.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: entityAsMap,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'save'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public schema (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productService/schema';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'schema'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public toCsv (payload: any, extraHttpRequestParams?: any ) : ApiResource<void> {
        const localVarPath = this.basePath + '/std/productService/toCsv';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling toCsv.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                        responseType: 'arraybuffer',
            transformResponse: (data, headers) => this.transformResponseAsFile({data: data, headers:headers}),
            params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'toCsv'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public toCsvAsArrayBuffer (payload: any, extraHttpRequestParams?: any ) : ApiResource<{arrayBuffer: ArrayBuffer}> & {arrayBuffer: ArrayBuffer} {
        const localVarPath = this.basePath + '/std/productService/toCsv';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling toCsvAsArrayBuffer.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                        responseType: 'arraybuffer',
            transformResponse: (arrayBuffer) => ({arrayBuffer: arrayBuffer}),
            params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'toCsvAsArrayBuffer'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
}
