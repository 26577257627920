import * as angular from 'angular';
import {IComponentOptions, IController} from 'angular';
import * as template from './job-validation-list.component.html';
import {mainModule} from '../app';
import {CosGridSort} from '../layout/cos-grid-sort';
import {
    JobsApi, ListJobsRequest
} from 'vtom-api-typescript-client';
import {ApiPageableHelper} from '../api/api-pageable-helper';
import {UserContextService} from '../user/user-context-service';

class Controller implements IController {
    filter: ListJobsRequest = {
        queryText: '',
        waitingForValidation: true,
        deleted: false,
    };
    columns = [
        {field: 'name', labelCode: 'name', linkBaseHref: '#/validation/job'},
        {field: 'customerName', labelCode: 'customer',},
        {field: 'openDate', labelCode: 'openDate', cellFilter: 'date:\'yyyy/MM/dd HH:mm:ss\''},
    ];
    sortInfo: CosGridSort = {fields: ['name'], directions: ['asc']};
    filterSelect = [];

    customerNamesById: { [key: number]: string };

    constructor(
        private readonly $routeParams,
        private readonly JobsApi: JobsApi,
        private readonly CustomerResource,
        private readonly UserContextService: UserContextService) {
    }

    findPage = (params) => {
        const query = angular.extend({}, params.query);
        ApiPageableHelper.addApiPageable(params.pageable, query);
        query.sortProperties = query.sortProperties.map(property => property == 'customerName' ? 'customer.name' : property);

        return this.JobsApi.listJobs(query).then(page => {
            page.content.forEach(job => job.customerName = this.customerNamesById[job.customerId]);
            return page;
        });
    };

    $onInit(): void {
        this.UserContextService.getContext().then(userContext => {
            if (!userContext.hasRole('ADMIN')) {
                // Only admins can see the customer column
                this.columns = this.columns.filter(column => column.field != 'customerName');
            }
            return this.CustomerResource.findAll({query: {}}).$promise;
        }).then(customers =>
            this.customerNamesById = customers.reduce((map, customer) => {
                map[customer.id] = customer.name;
                return map;
            }, {})
        );
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('jobValidationList', component);
