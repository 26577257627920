import {mainModule} from "../app";
import {Product, Scenario} from 'vtom-api-typescript-client';
import {IDeferred, IPromise, IQService} from "angular";
import {Renderer} from "@cosium/try-on-api-rendering";
import {Tracker} from "@cosium/try-on-api-tracking";
import {Config} from "@cosium/try-on-api-core";

/**
 * Hold a shared instance of the Try On library, that can be re-used across components.
 * Consumers are expected to call {@link TryOnApi#release} after using it.
 */
export class TryOnApiService {
    static NAME = 'TryOnApiService';

    private readonly renderer: Renderer;
    private readonly tracker: Tracker;
    private initialised: IPromise<[Tracker, Renderer]>;
    private acquireQueue: IDeferred<TryOnApi>[] = [];

    constructor(private readonly $q: IQService) {
        const apiConfig = new Config({
            serverURL: './',
            assetsFolder: './misc/tryon',
            licences: [
                '370-037-786-987-153-137-557-991-118-114-223.vlc', // cosium.com
            ]
        });
        this.tracker = new Tracker(apiConfig);
        this.renderer = new Renderer(apiConfig);
        this.initialised = $q.all([this.tracker.init(), this.renderer.init()]);
    }

    public acquire(): IPromise<TryOnApi> {
        this.acquireQueue.push(this.$q.defer());
        if (this.acquireQueue.length == 1) {
            this.resolveFirstAcquire();
        }
        return this.initialised.then(() => this.acquireQueue[this.acquireQueue.length - 1].promise);
    }

    private release(): void {
        this.acquireQueue.shift();
        this.resolveFirstAcquire();
    }

    private resolveFirstAcquire(): void {
        if (!this.acquireQueue.length) {
            return;
        }
        this.acquireQueue[0].resolve(new TryOnApi(this.tracker, this.renderer, () => this.release()));
    }
}

export class TryOnApi {
    constructor(public readonly tracker: Tracker,
                public readonly renderer: Renderer,
                private readonly onRelease: () => void) {
    }

    public release(): void {
        this.tracker.stop();
        this.renderer.stop();
        this.onRelease();
    }
}

mainModule.service(TryOnApiService.NAME, TryOnApiService);

