import {IAttributes, IDirective, IDirectiveFactory, INgModelController, IScope} from "angular";
import {mainModule} from "../app";

/**
 * Remove transparent borders around an image.
 * Works by creating a canvas, detect/crop it, then overwrite the original image with the canvas' as data url.
 */
class TransparentTrimDirective implements IDirective {

    restrict: string = 'A';
    ignoreNextLoad = false;

    link(scope: IScope, element: JQuery, attrs : IAttributes, ngModel: INgModelController) {
        if (element.prop('nodeName') != 'IMG') {
            throw 'trim-whitespace directive can only be used on <img> elements.';
        }
        element.on('load', () => this.trimWhitespace(element));
    }

    private trimWhitespace(element: JQuery): void {
        if (this.ignoreNextLoad) {
            this.ignoreNextLoad = false;
            return;
        }

        const img = element[0];
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0);

        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imgData.data;

        const cropBox = this.getBoundingBox(data, canvas.width, canvas.height);
        // const cropBox = {minX: 0, maxX:1134, minY: 0, maxY: 788};

        const croppedCanvas = document.createElement('canvas');
        const croppedCtx = croppedCanvas.getContext('2d');
        const width = cropBox.maxX - cropBox.minX + 1;
        const height = cropBox.maxY - cropBox.minY + 1;

        croppedCanvas.width = width;
        croppedCanvas.height = height;
        croppedCtx.drawImage(canvas, cropBox.minX, cropBox.minY, width, height, 0, 0, width, height);

        // Avoid recursive loop, replacing the src attribute will trigger a new load event
        this.ignoreNextLoad = true;
        element.attr('src', croppedCanvas.toDataURL());
    }

    private getBoundingBox(data: Uint8ClampedArray, width: number, height: number): Box {
        let minX = width, minY = height, maxX = -1, maxY = -1;
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                let alpha = data[(y * width + x) * 4 + 3];
                if (alpha > 100) {
                    if (x < minX) minX = x;
                    if (y < minY) minY = y;
                    if (x > maxX) maxX = x;
                    if (y > maxY) maxY = y;
                }
            }
        }
        return { minX: minX, minY: minY, maxX: maxX, maxY: maxY };
    }

    static factory() : IDirectiveFactory {
        let directiveFactory: IDirectiveFactory = () => new TransparentTrimDirective();
        directiveFactory.$inject = [];
        return directiveFactory;
    }
}

interface Box {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}
mainModule.directive('transparentTrim', TransparentTrimDirective.factory());
