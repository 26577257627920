/* Legacy modules */

import * as angular from 'angular';

/* Styles */
import "components-font-awesome/scss/font-awesome.scss";

/* Third party legacy angular modules */
import 'sprintf-js/src/sprintf';
import 'angular-xeditable/dist/js/xeditable';
import 'angular-xeditable/dist/css/xeditable.css';
import 'angular-rison/dist/angular-rison';
import "@cosium/angular-url-parser/dist/angular-url-parser";
import "angular-translate/dist/angular-translate";
import "angular-translate-loader-static-files/angular-translate-loader-static-files";
import "angular-dynamic-locale/src/tmhDynamicLocale";
import "angular-promise-extras/angular-promise-extras";
import "angular-toastr/dist/angular-toastr.css";
import "ui-select/dist/select";
import "ui-select/dist/select.css";
import "angular-filter/dist/angular-filter";
import "@cosium/ui-grid/build/cos-ui-grid";
import "@cosium/ui-grid/ng-grid.css";
import "@flowjs/ng-flow/index";
import 'ez-plus/src/jquery.ez-plus.js';
import 'angular-ez-plus/js/angular-ezplus.js';

import * as ngRoute from "angular-route";
import * as uiBootstrap from "@cosium/angular-ui-bootstrap";
import * as ngResource from "angular-resource";
import * as toastr from "angular-toastr";
import * as ngSanitize from "angular-sanitize";

import vtomHttpModule from '../../http/target/classes/openapi/code/api.module';

/**
 * Bootstrapping
 */
export const mainModule = angular.module('virtual-try-on-manager', [
    vtomHttpModule.name,
    ngRoute,
    uiBootstrap,
    ngResource,
    toastr,
    ngSanitize,
    'xeditable',
    'rison',
    'xeditable',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'ngPromiseExtras',
    'ui.select',
    'angular.filter',
    'ngGrid',
    'flow',
    'urlParser',
    'ezplus',
]).run(function ($rootScope, $window, $http, editableOptions, $anchorScroll) {
    $rootScope.contextAppCode = $window.location.pathname.substring($window.location.pathname.indexOf('/') + 1,
    $window.location.pathname.lastIndexOf('/'));
    $rootScope.contextPath = '/' + $rootScope.contextAppCode;
    editableOptions.theme = 'bs2';
    $anchorScroll.yOffset = 50;
});

mainModule.run(['$rootScope', function ($rootScope) {
    $rootScope.title = "Virtual Try On Manager";
}]);

