import {IComponentOptions, IController} from "angular";
import * as template from "./try-on-360.component.html";
import {mainModule} from "../app";
import {Scenario, ScenarioType} from "vtom-api-typescript-client";

class Controller implements IController {
    scenario: Scenario;

    imageIndexes: number[];
    current = 0;

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.scenario) {
            this.imageIndexes = null;
            return;
        }
        if (this.scenario.type != ScenarioType.THREESIXTY) {
            throw 'Scenario must be of type THREESIXTY.';
        }
        this.imageIndexes = Array.from(Array(this.scenario.nbThreeSixtyView).keys());
    }

    getUrl(index: number): string {
        return `./api/scenarios/${this.scenario.id}/files/${index}`;
    }

    next(): void {
        this.setCurrent(this.current + 1);
    }

    previous(): void {
        this.setCurrent(this.current - 1);
    }

    private setCurrent(index: number): void {
        if (index < 0) {
            index = this.scenario.nbThreeSixtyView - 1;
        }
        if (index >= this.scenario.nbThreeSixtyView) {
            index = 0;
        }
        this.current = index;
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        scenario: '<'
    }
};

mainModule.component('tryOn360', component);
